import { styled } from 'buttered';

export let Content = styled('section')<{ mobileTop?: boolean }>`
  max-width: 930px;
  margin: 130px auto 20px auto;
  padding: 0px 15px;

  @media screen and (max-width: 500px) {
    margin: 60px auto 0px auto;
    padding: 15px;

    ${p =>
      p.mobileTop &&
      `
      margin: 80px auto 0px auto;
    `}
  }
`;

export let BigContent = styled('section')`
  max-width: 1200px;
  margin: 130px auto 20px auto;
  padding: 0px 15px;

  @media screen and (max-width: 500px) {
    margin: 15px auto 0px auto;
    padding: 15px;
  }
`;
