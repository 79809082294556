import { styled } from 'buttered';

export let Title = styled('h1')`
  font-size: 2.8em;
  font-weight: 800;

  @media screen and (max-width: 600px) {
    font-size: 2.4em;
  }
`;

export let BigTitle = styled('h1')`
  font-size: 3.6em;
  font-weight: 700;

  .play {
    font-size: 1.5em;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.8em;

    .play {
      font-size: 1.3em;
    }
  }
`;
