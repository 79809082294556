import { styled } from 'buttered';

export let PricingGrid = styled('div')`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1100px;
  margin: 50px auto 0px auto;
  padding: 0px 10px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 750px) {
    margin: 0px auto;
  }
`;

export let PriceTaxNote = styled('p')`
  padding: 15px;
  margin: 30px auto 200px auto;
  text-align: center;

  @media screen and (max-width: 750px) {
    margin: 10px auto 50px auto;
  }
`;

export let Price = styled('div')`
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  h2 {
    font-weight: 600;
    font-size: 1.2em;
  }

  .price {
    font-weight: 700;
    font-size: 3em;
    margin: 5px 0px 3px 0px;
  }

  .price-extra {
    font-size: 0.9em;
    margin-bottom: 24px;
  }

  a {
    background: white;
    color: black;
    font-weight: 500;
    font-size: 1.2em;
    padding: 10px 20px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:focus-within {
    transform: scale(1.02);
  }
`;
