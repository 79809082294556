import { styled } from 'buttered';

export let BigSubtitle = styled('h2')`
  font-size: 3.4em;
  font-weight: 600;
  opacity: 0.35;

  @media screen and (max-width: 900px) {
    font-size: 1.8em;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.3em;
  }
`;
