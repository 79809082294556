import { Spinner, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

let Wrapper = styled('section')`
  border-radius: var(--vapor-radius-huge);
  box-shadow: var(--vapor-shadow-medium);
  border: var(--vapor-border);
  position: relative;
  overflow: hidden;

  width: 1600px;
  max-width: calc(100vw - 30px);
  margin: 0px auto;
  height: 900px;

  @media screen and (max-width: 600px) {
    height: 500px;
    margin-bottom: 40px;
  }
`;

let Overlay = styled('div')`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  transition: all 0.5s 0.5s;
  background: var(--vapor-background);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

let Frame = styled('iframe')`
  width: 100%;
  height: 100%;
  background: var(--vapor-background);
  transition: all 0.3s;
`;

export let DemoFrame = ({
  src,
  initialImage,
  canLoad,
  title
}: {
  src: string;
  initialImage: string;
  canLoad?: boolean;
  title: string;
}) => {
  let [loading, setLoading] = useState(false);
  let [canLoadLocal, setCanLoadLocal] = useState(() => canLoad);

  useEffect(() => {
    if (canLoad) {
      setTimeout(() => {
        setCanLoadLocal(true);
      }, 600);
    }
  }, [canLoad]);

  return (
    <Wrapper>
      <Overlay className={clsx({ hidden: !loading && canLoadLocal !== false })}>
        <VerticalCenter style={{ position: 'relative', zIndex: 3 }}>
          <div
            style={{
              width: 'fit-content',
              margin: '0px auto'
            }}
          >
            <Spinner />
          </div>
        </VerticalCenter>

        {/* <Preview
          style={{
            background: initialImage && `url('${initialImage}') no-repeat`,
            backgroundSize: 'contain'
          }}
        /> */}
      </Overlay>

      {canLoadLocal !== false && (
        <Frame src={src} frameBorder={0} onLoad={() => setLoading(false)} title={title} />
      )}
    </Wrapper>
  );
};
