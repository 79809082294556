import { Center } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';

let Wrapper = styled('figure')`
  position: relative;
  display: flex;
`;

let Content = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export let Squircle = ({
  children,
  size,
  color
}: {
  children?: React.ReactNode;
  size: number;
  color?: string;
}) => {
  return (
    <Wrapper
      style={{
        width: size,
        height: size
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 20C0 3.53 3.53 0 20 0C36.47 0 40 3.53 40 20C40 36.47 36.47 40 20 40C3.53 40 0 36.47 0 20Z"
          fill={color || 'var(--vapor-primary)'}
        />
      </svg>

      <Content>
        <Center>{children || null}</Center>
      </Content>
    </Wrapper>
  );
};
