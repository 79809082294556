import { styled } from 'buttered';

export let Grid3 = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

export let Grid = styled('div')`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: auto;
    gap: 25px;
  }
`;
