import { styled } from 'buttered';
import { Pause, Play } from 'react-feather';
import { usePomodoro, usePomodoroProvider } from '../state/usePomodoro';
import { TryMe } from './marketing/tryMe';

let Wrapper = styled('div')`
  padding: 0px 20px 35px 0px;
  display: flex;
  flex-direction: column;
`;

let Timer = styled('div')`
  border-radius: 15px;
  position: relative;
  background: var(--vapor-red);
  color: white;
  overflow: hidden;
  padding-top: 20px;
  height: 100%;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.3);
`;

let TimerProgressIndicator = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

let TimerContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 720px) {
    height: auto;
  }
`;

let TimerInner = styled('div')`
  max-width: 500px;
  margin: 0px auto;
  text-align: center;
  padding: 40px 15px 60px 15px;
  z-index: 5;
  position: relative;
  max-height: 100%;
`;

let Header = styled('h1')`
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 15px;

  @media screen and (max-width: 720px) {
    font-weight: 600;
    font-size: 1em;
    margin-bottom: 5px;
  }
`;

let Time = styled('h2')`
  font-weight: 700;
  font-size: 3em;
  display: flex;
  line-height: 1;
`;

let TimerButton = styled('button')`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: none;
  background: white;
  margin-top: 25px;
`;

export let Pomodoro = () => {
  usePomodoroProvider();
  let {
    start,
    state,
    blink,
    paused,
    resume,
    reset,
    pause,
    prettyTime,
    progress,
    noAnimation
  } = usePomodoro();

  let setup = () => {
    start();
  };

  return (
    <Wrapper>
      <Timer
        style={{
          background: state == 'break' ? 'var(--pomodoro-break-background)' : undefined,
          transition: 'background .3s'
        }}
      >
        <TimerContent>
          <TimerProgressIndicator
            style={{
              width: `${progress}%`,
              transition: paused || noAnimation ? undefined : 'all 0.3s linear'
            }}
          />

          <TimerInner>
            <Header>{state == 'break' ? 'Break' : 'Pomodoro'}</Header>

            <Time>
              <div style={{ width: 100, textAlign: 'right' }}>{prettyTime.minutes}</div>
              <div
                style={{
                  width: 50,
                  textAlign: 'center',
                  opacity: blink ? 1 : 0,
                  lineHeight: 0.9
                }}
              >
                :
              </div>
              <div style={{ width: 100, textAlign: 'left' }}>{prettyTime.seconds}</div>
            </Time>

            <TimerButton
              style={{
                color:
                  state == 'break' ? 'var(--pomodoro-break-background)' : 'var(--vapor-red)'
              }}
              onClick={state == 'setup' ? setup : paused ? resume : pause}
              title="Start Timer"
            >
              {paused || state == 'setup' ? (
                <Play
                  fill="currentColor"
                  style={{
                    marginLeft: 3,
                    marginTop: 2
                  }}
                />
              ) : (
                <Pause fill="currentColor" />
              )}
            </TimerButton>
          </TimerInner>
        </TimerContent>
      </Timer>

      <div style={{ margin: '20px 30px 0px auto' }}>
        <TryMe height={50} />
      </div>
    </Wrapper>
  );
};
