import { Button, Skeleton } from '@vapor/ui';
import { styled } from 'buttered';
import { Fragment } from 'react';
import { Home } from 'react-feather';
import { useUser } from '../state/user';

let Wrapper = styled('div')`
  width: 1200px;
  max-width: calc(100vw - 30px);
  margin: 0px auto;
  padding: 45px 50px 50px 50px;
  border-radius: 20px;
  background: var(--vapor-background);
  text-align: left;
  position: relative;
  z-index: 100;

  h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    font-weight: 600;
  }

  @media screen and (max-width: 700px) {
    padding: 35px;
    margin-top: 10px;

    h2 {
      font-size: 1.4em;
      margin-bottom: 15px;
    }
  }
`;

let Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
  }
`;

let Link = styled('a')`
  background: var(--vapor-accent-3);
  padding: 20px;
  border-radius: 15px;
  color: var(--vapor-foreground);
  border: solid 2px transparent;
  transition: all 0.3s;

  h3 {
    font-size: 1.2em;
    font-weight: 600;
  }

  &:hover,
  &:focus {
    border: solid 2px var(--vapor-primary);
  }
`;

export let Chooser = () => {
  let { teams } = useUser();

  return (
    <Wrapper>
      <h2>Choose a calendar or board to continue</h2>

      <Grid>
        {(teams || []).map(team => (
          <Fragment key={team.id}>
            {team.calendars.map(calendar => (
              <Link href={`/view/${calendar.id}`}>
                <h3>{calendar.name}</h3>
                <p>{team.name}</p>
              </Link>
            ))}
          </Fragment>
        ))}

        {!Array.isArray(teams) && (
          <>
            <Skeleton loading full radius={15}>
              <Link style={{ background: 'white' }}>
                <h3>Loading</h3>
                <p>...</p>
              </Link>
            </Skeleton>

            <Skeleton loading full radius={15}>
              <Link style={{ background: 'white' }}>
                <h3>Loading</h3>
                <p>...</p>
              </Link>
            </Skeleton>

            <Skeleton loading full radius={15}>
              <Link style={{ background: 'white' }}>
                <h3>Loading</h3>
                <p>...</p>
              </Link>
            </Skeleton>
          </>
        )}
      </Grid>

      <div>
        <a href="/app" style={{ width: 'fit-content', display: 'block' }}>
          <Button as="div" icon={<Home />} variant="primary" size="big">
            Open Launchpad
          </Button>
        </a>
      </div>
    </Wrapper>
  );
};
