import { Button, LI, Spacer, UL, VerticalCenter } from '@vapor/ui';
import Head from 'next/head';
import Link from 'next/link';
import Script from 'next/script';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Bookmark,
  Calendar,
  File,
  List,
  Mail,
  MessageCircle,
  Repeat,
  Smartphone,
  UploadCloud
} from 'react-feather';
import { FadeIn } from '../components/animation/fadeIn';
import { FadeInUp } from '../components/animation/fadeInUp';
import { Center } from '../components/boxes/center';
import { BigContent } from '../components/boxes/content';
import { Header, HeaderInner, HeaderMask } from '../components/boxes/header';
import { HighlightSection, HighlightSectionInner } from '../components/boxes/highlightSection';
import { Squircle } from '../components/boxes/squircle';
import { Chooser } from '../components/chooser';
import { Grid } from '../components/grid';
import { BottomCTA } from '../components/marketing/bottomCta';
import { Buttons } from '../components/marketing/buttons';
import { Card, CardGrid, CardGridWrapper } from '../components/marketing/card';
import { DemoFrame } from '../components/marketing/demoFrame';
import { Price, PriceTaxNote, PricingGrid } from '../components/marketing/pricing';
import { ReallyBigText } from '../components/marketing/reallyBigText';
import { Pomodoro } from '../components/pomodoro';
import { BigSubtitle } from '../components/text/bigSubtitle';
import { HeaderSubtitle, MainHeader } from '../components/text/mainHeader';
import { Mark } from '../components/text/mark';
import { Subtitle } from '../components/text/subtitle';
import { BigTitle } from '../components/text/title';
import { config } from '../config';
import { MarketingLayout } from '../layout/marketing';
import { useIsLoggedIn, useUser } from '../state/user';
import { Demo } from '../svgs/demo';
import { ShareIcon } from '../svgs/share';
import { TeamIcon } from '../svgs/team';

declare global {
  interface Window {
    Paddle: any;
  }
}

export default function Home() {
  let { loggedIn } = useIsLoggedIn();
  let featuresStartRef = useRef() as React.RefObject<HTMLDivElement>;
  let { user, loading } = useUser(true);

  let demoUrl = useMemo(() => {
    let url = new URL('https://demo-next.weekrise.com/view/demo');
    if (user?.photoUrl) url.searchParams.set('photoUrl', user?.photoUrl);

    return url.toString();
  }, [user?.photoUrl]);

  let [prices, setPrices] = useState({
    pro: '$4',
    team: '$12',
    free: '$0'
  });

  let setupPaddle = () => {
    if (!window.Paddle) return;

    if (config.paddle.isSandbox) window.Paddle.Environment.set('sandbox');
    window.Paddle.Setup({ vendor: config.paddle.vendorId });

    window.Paddle.Product.Prices(config.paddle.proMonthlyId, price => {
      setPrices(p => ({
        ...p,
        pro: price.recurring.price.net.replace('.00', '').replace('US$', '$'),
        free: price.price.net.replace('.00', '').replace('US$', '$')
      }));
    });

    window.Paddle.Product.Prices(config.paddle.teamMonthlyId, price => {
      setPrices(p => ({
        ...p,
        team: price.recurring.price.net.replace('.00', '').replace('US$', '$')
      }));
    });
  };

  useEffect(() => setupPaddle(), []);

  return (
    <MarketingLayout>
      <Script strategy="beforeInteractive">{`
        if (document.cookie && document.cookie.indexOf('token=') >= 0) {
          document.documentElement.classList.add("hide_unauth_header");
        } 
      `}</Script>

      <Script
        src="https://cdn.paddle.com/paddle/paddle.js"
        onLoad={() => setupPaddle()}
      ></Script>

      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `.hide_unauth_header .unauth_header { display: none; }`
          }}
        />
      </Head>

      <Header>
        {loggedIn ? (
          <>
            <HeaderInner className="smallMarginBottom">
              <MainHeader>Welcome back to Weekrise!</MainHeader>
            </HeaderInner>

            <Chooser />
          </>
        ) : (
          <>
            <HeaderInner className="unauth_header">
              <FadeIn>
                <MainHeader>
                  <span>Task Management.</span>
                  <br />
                  <span>Made Easy.</span>
                </MainHeader>
              </FadeIn>

              <Spacer height="max(4vh, 16px)" />

              <FadeIn delay={300}>
                <HeaderSubtitle>
                  Weekrise is a simple <br className="hr-desktop" />
                  and effective weekly task planner.
                </HeaderSubtitle>
              </FadeIn>

              <Spacer height="max(3vh, 16px)" />

              <FadeIn delay={600}>
                <Buttons>
                  <Link href={loggedIn ? '/app' : '/auth/signup'}>
                    <a>
                      <Button size="big" variant="primary">
                        Get Started
                      </Button>
                    </a>
                  </Link>

                  <Button
                    size="big"
                    variant={{
                      normal: {
                        background: 'transparent',
                        border: 'var(--vapor-primary)',
                        color: 'var(--vapor-primary)'
                      },
                      focus: {
                        background: 'var(--vapor-pink)',
                        border: 'var(--vapor-pink)',
                        color: 'var(--vapor-primary-text)'
                      }
                    }}
                    onClick={() => {
                      if (!featuresStartRef.current) return;

                      window.scrollTo({
                        top:
                          featuresStartRef.current.getBoundingClientRect().top +
                          window.scrollY -
                          40,
                        behavior: 'smooth'
                      });
                    }}
                  >
                    Learn more
                  </Button>
                </Buttons>
              </FadeIn>
            </HeaderInner>

            <Center className="unauth_header">
              <FadeInUp delay={1600}>
                <Demo size={300} />
              </FadeInUp>
            </Center>

            <Spacer height="2em" />

            <Center className="unauth_header" style={{ zIndex: 5, position: 'relative' }}>
              <FadeIn delay={1000}>
                <DemoFrame
                  src={demoUrl}
                  canLoad={true}
                  initialImage="/screenshot.webp"
                  title="A demo of Weekrise"
                />
              </FadeIn>
            </Center>
          </>
        )}

        <HeaderMask />
      </Header>

      <div className="features-waypoint" ref={featuresStartRef} />

      <BigContent>
        <BigTitle>
          Task management, designed for <br className="hr-desktop" /> people, not robots.
        </BigTitle>

        <Spacer height="2.2em" />

        <BigSubtitle>
          Weekrise’s feature-set has been carefully
          <br className="hr-desktop" /> chosen to help you get work done,
          <br className="hr-desktop" /> without getting in your way.
        </BigSubtitle>
      </BigContent>

      {/* <FadeIn whenInView>
        <BigCard
          image={<img src="/assets/features/projects.webp" />}
          background="linear-gradient(270deg, #8EC5FC 0%, #E0C3FC 100%);"
          color="white"
          title={
            <>
              Get organized <br className="hr-desktop" />
              with projects.
            </>
          }
          description={
            <>With projects you can keep track of goals or just group tasks together.</>
          }
        >
          In Weekrise, projects allow you to organize tasks further. Not only can you assign
          projects to tasks on the calendar, but you also have a board for each project where
          you can put tasks for which you don’t have a date.
        </BigCard>
      </FadeIn> */}

      <FadeIn whenInView delay={200}>
        <ReallyBigText>
          Enhance your productivity without
          <br className="hr-desktop" /> spending hours organizing <br className="hr-desktop" />
          calendars and to-do lists.
        </ReallyBigText>
      </FadeIn>

      <HighlightSection
        style={{
          background: '#1A1A1A',
          color: 'white',
          marginBottom: 'calc(-15vh - 50px)',
          overflow: 'auto'
        }}
      >
        <HighlightSectionInner className="bigger">
          <BigTitle style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 500 }}>Supercharge your</span>
            <br />
            <span className="play">productivity</span>
          </BigTitle>

          <Spacer height="max(4vh, 16px)" />

          <Subtitle style={{ textAlign: 'center' }}>
            Use the command pallet to
            <br className="hr-desktop" /> fly through Weekrise using your keyboard.
          </Subtitle>

          <Spacer height="max(8vh, 30px)" />

          <img
            style={{
              width: 700,
              maxWidth: '90vw',
              margin: '0px auto',
              display: 'block',
              boxShadow: '0px 0px 10px rgba(60, 60, 60, 0.5)',
              borderRadius: 15,
              background: 'black'
            }}
            src="/command.webp"
            alt="An image showcasing the weekrise command pallete."
          />
        </HighlightSectionInner>
      </HighlightSection>

      <HighlightSection
        style={{
          background: '#efefef',
          color: 'black'
        }}
      >
        <HighlightSectionInner>
          <Grid>
            <VerticalCenter>
              <BigTitle>
                Work together. <br className="hr-desktop" />
                Seamlessly.
              </BigTitle>

              <Spacer height="1.5em" />

              <Subtitle>Weekrise is built for collaboration and sharing.</Subtitle>
            </VerticalCenter>

            <Center style={{ textAlign: 'center' }}>
              <img
                src="/assets/features/people.webp"
                alt="An image of people"
                style={{ width: '60%' }}
              />
            </Center>
          </Grid>

          <Spacer height="max(7vh, 20px)" />

          <CardGrid style={{ maxWidth: '100%', margin: '0px' }}>
            <Card
              background="rgba(255, 255, 255)"
              icon={<TeamIcon size={50} />}
              title={
                <>
                  Share it with <br className="hr-desktop" />
                  <Mark color="#2481FF">your team</Mark>.
                </>
              }
            >
              Invite people to work together. You can easily assign tasks and see who's working
              on what.
            </Card>

            <Card
              background="rgba(255, 255, 255)"
              icon={<ShareIcon size={50} />}
              title={
                <>
                  Share it with <br className="hr-desktop" />
                  <Mark color="#FAACA8">the world</Mark>.
                </>
              }
            >
              Make your calendar public for everyone to see - they won't even need a Weekrise
              account.
            </Card>
          </CardGrid>
        </HighlightSectionInner>
      </HighlightSection>

      <HighlightSection
        style={{
          color: 'black',
          overflow: 'auto'
        }}
      >
        <HighlightSectionInner className="bigger">
          <BigTitle style={{ textAlign: 'center' }}>
            <span className="play">Pomodoro</span>
          </BigTitle>

          <Spacer height="max(1vh, 16px)" />

          <Subtitle style={{ textAlign: 'center' }}>
            Beat procrastination with <br className="hr-desktop" />
            the built-in pomodoro timer.
          </Subtitle>

          <Spacer height="max(6vh, 30px)" />

          <Pomodoro />
        </HighlightSectionInner>
      </HighlightSection>

      <HighlightSection
        style={{
          background: 'var(--vapor-primary)',
          color: 'var(--vapor-primary-text)',
          overflow: 'auto'
        }}
      >
        <HighlightSectionInner className="biggest">
          <BigTitle>
            <span style={{ lineHeight: 1.5 }}>
              Manage big projects with{' '}
              <mark
                style={{
                  background: 'var(--vapor-primary-text)',
                  color: 'var(--vapor-primary)',
                  padding: '0px 0.24em'
                }}
              >
                Boards
              </mark>
              .
            </span>
          </BigTitle>

          <Spacer height="max(2.5vh, 20px)" />

          <Subtitle style={{ opacity: 0.76 }}>
            Some things don't fit in a calendar, that's where boards come in. Use boards to
            organize tasks and projects in a kanban-board.
          </Subtitle>
        </HighlightSectionInner>
      </HighlightSection>

      <CardGridWrapper>
        <div style={{ marginBottom: 'max(20px, 6vh)' }}>
          <BigTitle>We've got you covered.</BigTitle>

          <Spacer height="0.5em" />

          <BigSubtitle>A feature-set that makes sense.</BigSubtitle>
        </div>

        <Card
          icon={
            <Squircle color="#10ac84" size={50}>
              <Mail color="white" size={30} />
            </Squircle>
          }
          title={
            <>
              Daily <Mark color="#10ac84">Digest</Mark>
            </>
          }
        >
          A report of your tasks for the day delivered to your inbox.
        </Card>

        <CardGrid>
          <Card
            icon={
              <Squircle color="#FFBB00" size={50}>
                <Smartphone color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                Manage tasks <Mark color="#FFBB00">on the go</Mark>
              </>
            }
          >
            Weekrise is optimized for mobile devices.
          </Card>

          <Card
            icon={
              <Squircle color="#45AAF2" size={50}>
                <Repeat color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                <Mark color="#45AAF2">Repeating</Mark> tasks
              </>
            }
          >
            Things repeat. That's why Weekrise has full support for repeating tasks.
          </Card>

          <Card
            icon={
              <Squircle color="#a55eea" size={50}>
                <List color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                Get detailed, <br className="hr-desktop" /> with{' '}
                <Mark color="#a55eea">subtasks</Mark>
              </>
            }
          >
            Subtasks are a great way to keep track of your progress.
          </Card>

          <Card
            icon={
              <Squircle color="#f78fb3" size={50}>
                <UploadCloud color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                Always <Mark color="#f78fb3">in sync</Mark>,<br className="hr-desktop" />{' '}
                across all devices
              </>
            }
          >
            Your Tasks, Projects, and Calendars are synchronized across all your devices.
          </Card>

          <Card
            icon={
              <Squircle color="#FC5C65" size={50}>
                <Calendar color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                <Mark color="#FC5C65">Google</Mark> Calendar sync
              </>
            }
          >
            Events from your Google Calendar appear in Weekrise.
          </Card>

          <Card
            icon={
              <Squircle color="var(--vapor-primary)" size={50}>
                <Bookmark color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                Categorize with <Mark color="var(--vapor-primary)">projects</Mark>
              </>
            }
          >
            Use projects to keep track of what a task is for.
          </Card>

          <Card
            icon={
              <Squircle color="#fa8231" size={50}>
                <File color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                <Mark color="#fa8231">Comment</Mark> on Tasks
              </>
            }
          >
            Discus progress or ask questions without leaving Weekrise.
          </Card>

          <Card
            icon={
              <Squircle color="#2bcbba" size={50}>
                <MessageCircle color="white" size={30} />
              </Squircle>
            }
            title={
              <>
                Save <Mark color="#2bcbba">Attachments</Mark>
              </>
            }
          >
            Attach extra docs and files to your tasks.
          </Card>
        </CardGrid>
      </CardGridWrapper>

      <HighlightSection
        style={{
          background: 'url(/heart.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'black'
        }}
      >
        <HighlightSectionInner className="full">
          <div style={{ fontSize: '1.2em' }}>
            <BigTitle style={{ textAlign: 'center' }}>
              <span>Everybody works</span> <br className="hr-desktop" />
              <span className="play">differently</span>
            </BigTitle>

            <Spacer height="max(3vh, 25px)" />

            <Subtitle style={{ textAlign: 'center' }}>
              That’s why Weekrise doesn't force
              <br className="hr-desktop" /> you to use any task-management methodology.
            </Subtitle>
          </div>
        </HighlightSectionInner>
      </HighlightSection>

      <HighlightSection
        style={{
          backgroundPosition: 'center',
          color: 'black'
        }}
        id="pricing"
      >
        <HighlightSectionInner style={{ marginBottom: 0 }}>
          <BigTitle style={{ textAlign: 'center' }}>Pricing</BigTitle>

          <Spacer height="max(1vh, 19px)" />

          <Subtitle style={{ textAlign: 'center' }}>
            No investors. Just great people, like you.
          </Subtitle>
        </HighlightSectionInner>
      </HighlightSection>

      <PricingGrid>
        <Price style={{ border: 'var(--vapor-border)', color: 'black' }}>
          <h2>Free</h2>

          <p className="price">{prices.free}</p>
          <p className="price-extra">/month</p>

          <UL>
            <LI>1 Collaborator</LI>
            <LI>∞ Tasks</LI>
            <LI>1 Calendar</LI>
            <LI>1 Board</LI>
            <LI>1 Synced Google Calendar</LI>
          </UL>

          <Spacer />

          <small>No credit-card required.</small>

          <Spacer height="6px" />

          <a
            style={{
              background: 'var(--vapor-primary)',
              color: 'var(--vapor-primary-text)'
            }}
            href={loggedIn ? '/app' : '/auth/signup'}
          >
            Sign up
          </a>
        </Price>

        <Price
          style={
            {
              background: 'var(--vapor-primary)',
              color: 'var(--vapor-primary-text)',
              '--vapor-foreground': 'var(--vapor-primary-text)'
            } as any
          }
        >
          <h2>Pro</h2>

          <p className="price">{prices.pro}</p>
          <p className="price-extra">/month</p>

          <UL>
            <LI>10 Collaborators</LI>
            <LI>∞ Tasks</LI>
            <LI>∞ Calendars</LI>
            <LI>∞ Boards</LI>
            <LI>∞ Synced Google Calendars</LI>
            <LI>Attachments</LI>
            <LI>Analytics</LI>
            <LI>Daily Digest Email</LI>
            <LI>Pomodoro</LI>
            <LI>Export Tasks</LI>
            <LI>Filters and Tags</LI>
          </UL>

          <Spacer />

          <Spacer height="10px" />

          <a
            style={{
              color: 'var(--vapor-primary)'
            }}
            href={loggedIn ? '/app' : '/auth/signup'}
          >
            Get Started
          </a>
        </Price>

        <Price
          style={
            {
              background: '#a55eea',
              color: 'white',
              '--vapor-foreground': 'white'
            } as any
          }
        >
          <h2>Team</h2>

          <p className="price">{prices.team}</p>
          <p className="price-extra">/month (flat rate)</p>

          <UL>
            <LI>
              <strong>20 Team Members</strong>
            </LI>
            <LI>∞ Tasks</LI>
            <LI>∞ Calendars</LI>
            <LI>∞ Boards</LI>
            <LI>∞ Synced Google Calendars</LI>
            <LI>Attachments</LI>
            <LI>Analytics</LI>
            <LI>Daily Digest Email</LI>
            <LI>Pomodoro</LI>
            <LI>Export Tasks</LI>
            <LI>Filters and Tags</LI>
          </UL>

          <Spacer />

          <Spacer height="10px" />

          <a
            style={{
              color: '#a55eea'
            }}
            href={loggedIn ? '/app' : '/auth/signup'}
          >
            Get Started
          </a>
        </Price>
      </PricingGrid>

      <PriceTaxNote>Taxes and fees are added at checkout.</PriceTaxNote>

      <BottomCTA
        title={
          <>
            Get Organized
            <br />
            with Weekrise.
          </>
        }
        action="Get started for free"
        actionLink={loggedIn ? '/app' : '/auth/signup'}
      />
    </MarketingLayout>
  );
}
