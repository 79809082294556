import { styled } from 'buttered';

export let MainHeader = styled('h1')`
  font-size: 4.6rem;
  font-weight: 700;

  @media screen and (max-width: 750px) {
    font-size: 2em;
  }

  .play {
    font-size: 1.5em;
  }
`;

export let HeaderSubtitle = styled('h2')`
  font-size: 2.3rem;
  font-weight: 500;

  @media screen and (max-width: 750px) {
    font-size: 1.3em;
  }
`;
