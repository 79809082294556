import { styled } from 'buttered';

export let Subtitle = styled('h2')`
  font-size: 2.4em;
  font-weight: 600;
  opacity: 0.5;

  @media screen and (max-width: 750px) {
    font-size: 1.6em;
  }
`;
