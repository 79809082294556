import { Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';

let Wrapper = styled('section')`
  padding: 60px;
  background: var(--vapor-accent-1);
  border-radius: var(--vapor-radius-huge);
  height: 100%;

  @media screen and (max-width: 800px) {
    margin: 0px 10px;
    padding: 30px;
  }
`;

let Content = styled('main')``;

export let CardGrid = styled('div')`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin-top: 30px;

  @media screen and (max-width: 800px) {
    grid-template-columns: auto;
    gap: 15px;
    margin-top: 15px;
  }
`;

export let CardGridWrapper = styled('div')`
  max-width: 1200px;
  margin: 200px auto;

  @media screen and (max-width: 800px) {
    padding: 10px;
    margin: 40px auto;
  }
`;

export let Card = ({
  icon,
  title,
  children,
  background
}: {
  icon: React.ReactElement;
  title: React.ReactNode;
  children: React.ReactNode;
  background?: string;
}) => {
  return (
    <Wrapper style={{ background }}>
      <Content>
        <VerticalCenter>
          <div>
            {icon}

            <Spacer height="1.2em" />

            <Text as="h1" size={34} weight={600}>
              {title}
            </Text>

            <Spacer height="1em" />

            <Text as="p" size={24} weight={500}>
              {children}
            </Text>
          </div>
        </VerticalCenter>
      </Content>
    </Wrapper>
  );
};
