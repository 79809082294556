import { styled } from 'buttered';

export let HighlightSection = styled('header')`
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;
  color: white;
`;

export let HighlightSectionInner = styled('div')`
  max-width: 1200px;
  margin: 10vh auto;
  padding: 0px 10px;

  &.bigger {
    margin: 15vh auto;
  }

  &.biggest {
    margin: 18vh auto;
  }

  &.full {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 750px) {
    margin: 10px 0px !important;
    padding: 30px;
  }
`;
