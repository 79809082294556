import React from 'react';

export let ShareIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#FAACA8"
      />
      <path
        d="M64 40.6667C67.866 40.6667 71 37.5327 71 33.6667C71 29.8007 67.866 26.6667 64 26.6667C60.134 26.6667 57 29.8007 57 33.6667C57 37.5327 60.134 40.6667 64 40.6667Z"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 57C39.866 57 43 53.866 43 50C43 46.134 39.866 43 36 43C32.134 43 29 46.134 29 50C29 53.866 32.134 57 36 57Z"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64 73.3333C67.866 73.3333 71 70.1993 71 66.3333C71 62.4674 67.866 59.3333 64 59.3333C60.134 59.3333 57 62.4674 57 66.3333C57 70.1993 60.134 73.3333 64 73.3333Z"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0433 53.5233L57.98 62.81"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.9567 37.19L42.0433 46.4767"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
