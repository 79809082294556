import { Button } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { ArrowRight } from 'react-feather';

let Wrapper = styled('section')`
  background: var(--vapor-primary);
  color: var(--vapor-primary-text);
  padding: 100px 15px;
  width: 1600px;
  max-width: calc(100vw - 60px);
  margin: 100px auto 100px auto;
  border-radius: 20px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 800px) {
    max-width: calc(100vw - 20px);
    padding: 60px 15px;
    border-radius: 15px;
    margin: 0px auto 50px auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);

    aside {
      display: none;
    }
  }
`;

let Content = styled('main')`
  max-width: 720px;
  margin: 0px auto;
  text-align: center;

  h1 {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 40px;

    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
  }
`;

export let BottomCTA = ({
  title,
  action,
  actionLink
}: {
  title: React.ReactNode;
  action: string;
  actionLink: string;
}) => {
  return (
    <Wrapper>
      <Content>
        <h1>{title}</h1>

        <Link href={actionLink}>
          <a style={{ width: 'fit-content', margin: '0px auto', display: 'block' }}>
            <Button
              as="div"
              iconRight={<ArrowRight />}
              size="big"
              variant={{
                normal: {
                  color: 'var(--vapor-primary)',
                  background: 'var(--vapor-primary-text)',
                  border: 'var(--vapor-primary-text)'
                },
                focus: {
                  color: 'var(--vapor-primary-text)',
                  background: 'var(--vapor-primary)',
                  border: 'var(--vapor-primary-text)'
                }
              }}
              style={{ margin: '0px auto' }}
            >
              {action}
            </Button>
          </a>
        </Link>
      </Content>
    </Wrapper>
  );
};
