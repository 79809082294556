import { keyframes, styled } from 'buttered';
import { useInView } from 'react-hook-inview';

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, -20px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

let Wrapper = styled('section')`
  animation: ${fadeIn} 1s ease both;
`;

export let FadeIn = ({
  children,
  delay,
  whenInView
}: {
  children: React.ReactNode;
  delay?: number;
  whenInView?: boolean;
}) => {
  let [ref, isVisible] = useInView();

  return (
    <Wrapper
      ref={ref}
      style={{
        animationDelay: `${delay || 0}ms`,
        animationPlayState: !isVisible && whenInView ? 'paused' : 'running'
      }}
    >
      {children}
    </Wrapper>
  );
};
