import { useEffect, useMemo, useRef } from 'react';
import useDelayed from 'use-delayed';
import createGlobalState from 'use-state-global';

let getNow = () => (document.timeline ? document.timeline.currentTime : performance.now());

let accurateInterval = (callback: () => void, interval = 500) => {
  let counter = 1;
  let timeoutId: NodeJS.Timeout;
  let startTime = getNow();

  let main = () => {
    let nowTime = getNow();
    let nextTime = startTime + counter * interval;
    timeoutId = setTimeout(main, interval - (nowTime - nextTime));

    counter += 1;
    callback();
  };

  timeoutId = setTimeout(main, interval);

  return () => clearTimeout(timeoutId);
};

let useAnimationFrame = (ms: number, callback: () => void, enabled: boolean) => {
  let callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!enabled) return;

    return accurateInterval(() => callbackRef.current(), ms);
  }, [ms, enabled]);
};

let pomodoroDuration = 25 * 60 * 1000;
let breakDuration = 5 * 60 * 1000;

export let usePomodoroState = createGlobalState<'setup' | 'pomodoro' | 'break'>('setup');
export let usePomodoroPaused = createGlobalState(false);
let usePomodoroTimerState = createGlobalState<{
  time: number;
  startedAt: number;
  duration: number;
  totalDuration: number;
} | null>(null);

export let usePomodoroTime = () => {
  let [timerState] = usePomodoroTimerState();

  let prettyTime = useMemo(() => {
    if (!timerState?.time) {
      let minutes = Math.floor(pomodoroDuration / (60 * 1000));
      let seconds = Math.floor((pomodoroDuration % (60 * 1000)) / 1000);

      return {
        minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
        seconds: `${seconds < 10 ? '0' : ''}${seconds}`
      };
    }

    let minutes = Math.floor(timerState.time / (60 * 1000));
    let seconds = Math.floor((timerState.time % (60 * 1000)) / 1000);

    return {
      minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
      seconds: `${seconds < 10 ? '0' : ''}${seconds}`
    };
  }, [timerState?.time]);

  let progress = useMemo(() => {
    if (!timerState?.time) return 0;

    return 100 - (timerState.time / timerState.totalDuration) * 100;
  }, [timerState?.time, timerState?.totalDuration]);

  return {
    prettyTime,
    progress
  };
};

export let usePomodoroProvider = () => {
  let [paused] = usePomodoroPaused();
  let [state, setState] = usePomodoroState();
  let [timerState, setTimerState] = usePomodoroTimerState();

  useAnimationFrame(
    300,
    () => {
      if (paused || state == 'setup') return;

      let newState = {
        startedAt: timerState!.startedAt,
        totalDuration: timerState!.totalDuration,
        duration: timerState!.duration,
        time: timerState!.duration - (getNow() - timerState!.startedAt)
      };

      if (newState.time < 0) {
        if (state == 'pomodoro') {
          setState('break');
          setTimerState({
            startedAt: getNow(),
            totalDuration: breakDuration,
            duration: breakDuration,
            time: breakDuration
          });
        } else {
          setState('pomodoro');
          setTimerState({
            startedAt: getNow(),
            totalDuration: pomodoroDuration,
            duration: pomodoroDuration,
            time: pomodoroDuration
          });
        }
      } else {
        setTimerState(newState);
      }
    },
    state != 'setup'
  );
};

export let usePomodoro = () => {
  let [state, setState] = usePomodoroState();
  let [paused, setPaused] = usePomodoroPaused();
  let [timerState, setTimerState] = usePomodoroTimerState();

  let blink = useMemo(() => {
    if (paused || state == 'setup') return true;

    return Math.floor(timerState?.time / 1000) % 2;
  }, [paused, state, timerState]);

  let start = () => {
    if (state != 'setup') return;

    setState('pomodoro');
    setPaused(false);
    setTimerState({
      startedAt: getNow(),
      totalDuration: pomodoroDuration,
      duration: pomodoroDuration,
      time: pomodoroDuration
    });
  };

  let pause = () => {
    if (state == 'setup') return;

    setPaused(true);
  };

  let resume = () => {
    if (state == 'setup') return;

    setTimerState({
      startedAt: getNow(),
      totalDuration: timerState!.totalDuration,
      duration: timerState!.time,
      time: timerState!.time
    });
    setPaused(false);
  };

  let reset = () => {
    setState('setup');
    setTimerState(null);
  };

  let delayedState = useDelayed(state, 500);

  let { prettyTime, progress } = usePomodoroTime();

  return {
    start,
    pause,
    resume,
    reset,
    state,
    blink: paused || state == 'setup' ? true : blink,
    time: timerState?.time,
    noAnimation: delayedState != state,
    paused,
    prettyTime,
    progress
  };
};
