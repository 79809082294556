import { keyframes, styled } from 'buttered';

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, 10px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

let Wrapper = styled('section')`
  animation: ${fadeIn} 1s ease both;
`;

export let FadeInUp = ({ children, delay }: { children: React.ReactNode; delay?: number }) => {
  return <Wrapper style={{ animationDelay: `${delay || 0}ms` }}>{children}</Wrapper>;
};
